export const STRIPE_CHECKOUT_REQUEST = 'STRIPE_CHECKOUT_REQUEST'
export const STRIPE_CHECKOUT_SUCCESS = 'STRIPE_CHECKOUT_SUCCESS'
export const STRIPE_CHECKOUT_FAIL = 'STRIPE_CHECKOUT_FAIL'

export const STRIPE_SESSION_DATA_REQUEST = 'STRIPE_SESSION_DATA_REQUEST'
export const STRIPE_SESSION_DATA_SUCCESS = 'STRIPE_SESSION_DATA_SUCCESS'
export const STRIPE_SESSION_DATA_FAIL = 'STRIPE_SESSION_DATA_FAIL'

/**
 * //* New --> Stripe Charge with custom checkout flow
 */
export const STRIPE_CHARGE_REQUEST = 'STRIPE_CHARGE_REQUEST'
export const STRIPE_CHARGE_SUCCESS = 'STRIPE_CHARGE_SUCCESS'
export const STRIPE_CHARGE_FAIL = 'STRIPE_CHARGE_FAIL'
export const STRIPE_CHARGE_RESET = 'STRIPE_CHARGE_RESET'
