import axios from 'axios'
import { loadStripe } from '@stripe/stripe-js'

import {
  STRIPE_CHECKOUT_REQUEST,
  STRIPE_CHECKOUT_SUCCESS,
  STRIPE_CHECKOUT_FAIL,
  STRIPE_CHARGE_REQUEST,
  STRIPE_CHARGE_SUCCESS,
  STRIPE_CHARGE_FAIL,
  STRIPE_CHARGE_RESET,
  STRIPE_SESSION_DATA_REQUEST,
  STRIPE_SESSION_DATA_SUCCESS,
  STRIPE_SESSION_DATA_FAIL,
} from '../types/stripeTypes'

// Create Order
export const stripeCheckout = (order) => async (dispatch, getState) => {
  try {
    dispatch({
      type: STRIPE_CHECKOUT_REQUEST,
    })

    const stripePromise = loadStripe(
      'pk_test_51HgIrOAs4fA7BMN8fDy2gFlVV7OtOvVlQm0gpbG8jZyOHcuQKHON9CCD03ajPIxFKht659A2BOZ9b7Y40thlSXbm003uDpU3c2'
    )
    const stripe = await stripePromise

    // const {
    //   userLogin: { userInfo },
    // } = getState()

    const config = {
      headers: {
        'Content-Type': 'application/json',
        // Authorization: `Bearer ${userInfo.token}`,
      },
    }

    await axios
      .post(
        'https://us-central1-jade-and-aloe-ecommerce.cloudfunctions.net/api/stripe',
        order,
        config
      )
      .then((response) => {
        const { data } = response
        stripe.redirectToCheckout({
          sessionId: data.id,
        })
      })
      .catch((error) => console.log(error))

    dispatch({
      type: STRIPE_CHECKOUT_SUCCESS,
    })
  } catch (error) {
    dispatch({
      type: STRIPE_CHECKOUT_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const getStripeSessionData = (sessionId) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({ type: STRIPE_SESSION_DATA_REQUEST })

    //* Use get state to send an authorization header in GET request with token

    // const { data } = await axios.get(`/checkout-session?id=${id}`)
    const { data } = await axios.get(
      `https://us-central1-jade-and-aloe-ecommerce.cloudfunctions.net/api/stripe/session?id=${sessionId}`
    )

    dispatch({
      type: STRIPE_SESSION_DATA_SUCCESS,
      payload: data,
    })
  } catch (error) {
    dispatch({
      type: STRIPE_SESSION_DATA_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}

export const stripeCharge = (id, amount) => async (dispatch, getState) => {
  try {
    dispatch({ type: STRIPE_CHARGE_REQUEST })

    const {
      data,
    } = await axios.post(
      'https://us-central1-jade-and-aloe-ecommerce.cloudfunctions.net/api/stripe/charge',
      { id, amount }
    )

    dispatch({
      type: STRIPE_CHARGE_SUCCESS,
      payload: data,
    })

    dispatch({
      type: STRIPE_CHARGE_RESET,
    })
  } catch (error) {
    dispatch({
      type: STRIPE_CHARGE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    })
  }
}
